import React from "react";
import "../utils/default.css";
import Admin from "../Admin/Admin.jsx";
import RegisterForm from "../RegisterForm/RegisterForm.jsx";
import TopUsers from "../Admin/TopUsers.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginCAS from "../Login/LoginCAS";
import LogoutCas from "../Login/LogoutCas.jsx";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme, muiTheme } from "./theme.jsx";
import ToolSelection from "../User/ToolSelection.jsx";
import Profile from "../User/Profile.jsx";
import KioskLogin from "../Login/KioskLogin";
import InvalidScreen from "../Components/Invalid";
import BadgrCallback from "../User/BadgrCallback";
import Backend from "../utils/api";
import ThankYouPage from "../Components/ThankYou";
import LabStatus from "../LabStatus/LabStatus.jsx"
// import BadgrCallback from "../User/BadgrCallback";

class App extends React.Component {
  state = {
    user: {},
    lab: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // First try to get API key from query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get('apiKey');
    
    if (apiKey) {
      Backend.setApiKey(apiKey);
    } else {
      // Try to get API key from localStorage
      const storedKey = localStorage.getItem('pinpoint_kiosk_key');
      if (storedKey) {
        Backend.setApiKey(storedKey);
      } else {
        console.warn('No API key found in query parameters or localStorage');
      }
    }

    // Now proceed with lab determination after API key is set
    if (
      window.location.pathname.startsWith("/kiosk") ||
      window.location.pathname.startsWith("/machine_selection") ||
      window.location.pathname.startsWith("/lab_status")
    ) {
      // Validate API key before determining lab
      Backend.validateApiKey(Backend.apiKey || localStorage.getItem('pinpoint_kiosk_key'))
        .then(isValid => {
          if (isValid) {
            console.log("determining lab...");
            this.determineLab();
          } else {
            this.setState({ 
              lab: {
                id: "TL1",
                name: "Pinpoint",
              }
            });
          }
        })
        .catch(err => {
          console.error("Error validating API key:", err);
          this.setState({ 
            lab: {
              id: "Error",
              name: "Error Validating API Key",
            }
          });
        });
    }
    window.onpopstate = this.backButton;
  }

  determineLab = async () => {
    var lab;
    try {
      // first try getting lab from URL
      const urlParams = new URLSearchParams(window.location.search);

      const labParam = urlParams.get("lab");
      const siteNameParam = urlParams.get("siteName");

      const labId = labParam || siteNameParam;

      if (labId !== null) {
        lab = await Backend.getLabById({ id: labId, UID: "817500000" });
      } else {
        lab = JSON.parse(localStorage.getItem("lab"));
        // update URL to show lab parameter
        if (lab) {
          window.history.replaceState(null, null, `?lab=${lab.id}`);
        }
      }
    } catch (err) {
      lab = {
        id: "Error",
        name: "Error Loading Lab...",
      };
    }
    console.log("Setting lab to: ");
    console.table(lab);
    this.setLab(lab);
    console.log("done setting lab.");
  };

  login = (e) => {
    this.setUser(e);
  };

  logout = () => {
    if (this.state.user) {
      this.setState({
        user: {},
      });
    }
    window.location = "/";
  };

  setLab = (lab) => {
    localStorage.setItem("lab", JSON.stringify(lab));
    this.setState({ lab: lab });
  };

  setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user: user });
  }

  render() {
    const user = this.state.user;
    const lab = this.state.lab;

    return (
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={muiTheme}>
          <Router>
            <Switch>
              <Route exact path="/kiosk">
                <KioskLogin
                  onSubmit={(e) => {
                    this.setUser(e);
                  }}
                  currLab={lab}
                  setCurrLab={this.setLab}
                  routeFunc={(user_res, lab_permission_res) => {
                    if (user_res.status === 200) {
                      return "/machine_selection";
                    } else {
                      return "/register";
                    }
                  }}
                />
              </Route>
              <Route exact path="/">
                <LoginCAS
                  onSubmit={(e) => this.setUser(e)}
                />
              </Route>

              <Route exact path="/caslogin">
                <LoginCAS
                  onSubmit={(e) => this.setUser(e)}
                />
              </Route>

              <Route
                path="/machine_selection"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/kiosk" />
                  ) : (
                    <ToolSelection user={user} lab={lab} />
                  )
                }
              />
              <Route
                path="/invalid"
                render={(props) => <InvalidScreen></InvalidScreen>}
              />
              <Route path="/oauth/*">
                <BadgrCallback
                  user={ JSON.parse(localStorage.getItem("user")) }
                />
              </Route>
              <Route
                path="/profile"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/" />
                  ) : (
                    <Profile user={user} />
                  )
                }
              />
              <Route
                path="/admin"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/" />
                  ) : (
                    <Admin user={user} />
                  )
                }
              />
              <Route path="/logout">
                <LogoutCas></LogoutCas>
              </Route>

              <Route path="/topusers" render={(props) => <TopUsers />} />
              <Route path="/register">
                <RegisterForm
                  UID={user.UID}
                  currLab={lab}
                  routeFunc={(account_creation_res) => {
                    if (
                      account_creation_res.status === 200 ||
                      account_creation_res.status === 201
                    ) {
                      return "/machine_selection";
                    } else {
                      return "/kiosk";
                    }
                  }}
                  onSubmit={(e) => {
                    this.setUser(e);
                  }}
                />
              </Route>
              <Route path="/thank_you" render={(props) => <ThankYouPage></ThankYouPage>} />
              <Route exact path="/lab_status">
                <LabStatus
                  currLab={lab}
                />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </ChakraProvider>
    );
  }
}

export default App;
