import React, { useState, useEffect, useRef } from "react";
import Backend from "../utils/api.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import swipeAnimation from "../assets/swipeAnimation.json";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import Lottie from "lottie-react";
import {
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Box,
  Image,
  Spinner,
  Center,
  Alert, 
  AlertIcon, 
  AlertDescription,
  AlertTitle,
} from "@chakra-ui/react";
import Validators from "../utils/validators.jsx";
import SwipeOrReservePopup from "../Components/SwipeOrReservePopup.jsx";
import AdminPopup from "../Components/AdminPopup.jsx";
import SwipeOutReminderPopup from "../Components/SwipeOutReminderPopup.jsx";

/* 
  Login page for users to swipe their university ID card and be routed to the tool reservation page.
  This page is used as the way for labs to authenticate users in-person via swipe-ins.
*/
function KioskLogin({ onSubmit, routeFunc, history, currLab, setCurrLab }) {
  const SWIPE_IN = 1;
  const SWIPE_OUT = 2;
  const currLabName = (currLab && currLab.name) || "Loading";
  const [isLoading, setIsLoading] = useState(false);

  const [labs, setLabs] = useState([]);
  const [swipeMode, setSwipeMode] = useState(SWIPE_IN);
  const [input, setInput] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [showChoicePopup, setShowChoicePopup] = useState(false);
  const [showAdminPopup, setShowAdminPopup] = useState(false);
  const [showReminderPopup, setShowReminderPopup] = useState(false);
  const [UID, setUID] = useState("");
  const [apiKeyError, setApiKeyError] = useState(false);

  const inputRef = useRef(null);

  // Checks if user is swiped in or not and routes them to the appropriate page
  async function processSwipe(e) {
    e.preventDefault();
    setIsLoading(true);
    // ensure that the input is in proper format
    if (Validators.validateSwipeNum(input)) {
      // convert swipe number to UID
      timeout(8000, Backend.convertSwipeNumToUID(input))
        .then((res_convert) => {
          setUID(res_convert.UID);
          // get user info from UID
          timeout(8000, Backend.getUser(res_convert.UID))
            .then((res_getuser) => {
              // Check if UID from swipenum is in db. If it is, proceed to machine selection page.
              // If it isn't, proceed to registration screen
              if (res_getuser.status === 200 && res_convert.status === 200) {
                onSubmit(res_getuser);
                Backend.getLabPermissionsByUser(res_convert.UID)
                  .then((res_permissions) => {
                    var lab_permission_ids = res_permissions.items.reduce(function(map, l) {
                      map[l.lab] = l
                      return map
                    }, {});
                    if (currLab.id in lab_permission_ids || res_getuser.isSuperAdmin) {
                      setShowAdminPopup(true);
                    } else {
                      popupIfUserAutoSwipedOut(res_convert.UID, currLab.id);
                    }
                  });
              } else if (res_convert.status === 404) {
                flashErrorMessage("Card could not be found in the UMD system. Please swipe a valid university ID card.");
              } else if (res_convert.status === 401) {
                flashErrorMessage("The university API experienced an issue. If the issue persists, please contact eds@umd.edu.");
              } else {
                onSubmit({ status: res_convert.status, UID: res_convert.UID });
                typeof routeFunc === "function" && history.push("/register"); // go to register page
              }
              setIsLoading(false);
            })
            .catch((e) => {
              flashErrorMessage(
                "We were unable to process your request. Please try again, and contact eds@umd.edu if the issue persists."
              );
            });
        })
        .catch((e) => {
          flashErrorMessage(
            "We were unable to process your request. Please try again, and contact eds@umd.edu if the issue persists."
          );
        });
    } else {
      flashErrorMessage(
        "Card could not be found in the UMD system. Please swipe a valid university ID card."
      );
    }
    setCurrLab(currLab);
  }
    
  function popupIfUserSwipedIn(UID, labID) {
    Backend.isUserSwipedIn(UID, labID).then((swiped_in) => {
      if (swiped_in) {
        setShowChoicePopup(true); // prompt user to swipe out or reserve more machines with SwipeOrReserve component
      } else {
        Backend.userSwipeIn({
          userUID: UID,
          userSwipeNum: input,
          lab: labID,
        });
        typeof routeFunc === "function" &&
          history.push("/machine_selection"); // go to machine selection page
      }
    });
  }

  // Function to tell if a user was automatically swiped out last time they used this lab
  function popupIfUserAutoSwipedOut(UID, labID) {
    // Set to true if the user isn't currently swiped in and the last swipe out was automatic
    Backend.isLastSwipeOutAuto(UID).then((res) => {
      if (res) {
        setShowReminderPopup(true);
      } else {
        // Open the "sign out or reserve new tools" popup if needed
        popupIfUserSwipedIn(UID, labID);
      }
    });
  }

  // Used to create time out messages
  function timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject(
          new Error("We were unable to process your request. Please try again, and contact eds@umd.edu if the issue persists.")
        );
      }, ms);
      promise.then(resolve, reject);
    });
  }

  // Displays an auto-disappearing message to the user
  function flashErrorMessage(message) {
    setIsLoading(false);
    setInput("");
    setShowErrorMessage(true);
    setErrorMessageText(message);
    setTimeout(() => {
      setShowErrorMessage(false);
      setErrorMessageText("");
    }, 5000);
  }

  useEffect(() => {
    // Check for API key before making any requests
    const apiKey = Backend.apiKey || localStorage.getItem('pinpoint_kiosk_key');
    if (!apiKey) {
      setErrorMessageText(
        `This Pinpoint Kiosk is not properly configured. Please contact eds@umd.edu.`
      );
      setShowErrorMessage(true);
      return;
    }

    // Only fetch labs if we have an API key and no labs loaded yet
    if (labs.length === 0) {
      Backend.getLabs(1000).then((res) => {
        if (res && res.items) {
          setLabs(res.items);
          if (!currLab) {
            setCurrLab(res.items.length > 0 && res.items[0]);
          }
        }
      }).catch(error => {
        console.error('Error fetching labs:', error);
        setErrorMessageText("Error loading labs. Please check your API key.");
        setShowErrorMessage(true);
      });
    }
  }, [currLab, labs, setCurrLab]);

  return (
    <div
      onClick={() => {
        inputRef.current.focus();
      }}
    >
      <Stack>
        <Image
          alignSelf="start"
          width="3em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Flex
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Stack spacing={12} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Heading fontSize={"6xl"} fontWeight="bold">
                Welcome
              </Heading>
              <Text fontSize={"xl"} color={"gray.600"}>
                to {currLabName}
              </Text>
            </Stack>
            <Box
              rounded={"2xl"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
              paddingTop={0}
              height={"55vh"}
            >
              <form onSubmit={processSwipe}>
                <Center height={"25vh"} marginTop="5vh" marginBottom="25vh">
                  {isLoading ? (
                    <Spinner alignSelf="center" />
                  ) : (
                    <Lottie animationData={swipeAnimation} loop={true} />
                  )}
                </Center>

                <FormControl id="UID">
                  <Input
                    ref={inputRef}
                    type="password"
                    autoFocus={true}
                    outline="none"
                    border="none"
                    textColor={"transparent"}
                    focusBorderColor="none"
                    value={input}
                    onChange={(e) => {
                      const numMatch = e.target.value.match(/\d*/);
                      numMatch && setInput(numMatch[0]);
                    }}
                  />
                </FormControl>
              </form>
            </Box>
            <Center>
              <Box maxWidth="90vw">
              {showErrorMessage
                ? 
                <Alert status="error" variant='left-accent'>
                    <AlertIcon />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {errorMessageText}
                    </AlertDescription>
                  </Alert>

                : 
                <Text
                  fontSize={"md"}
                  color={showErrorMessage ? "red" : "gray.600"}
                  textAlign="center"
                >
                  Please swipe your university ID card to check in.
                </Text>}
                </Box>
            </Center>
          </Stack>
        </Flex>
        {showReminderPopup && (
          <SwipeOutReminderPopup
            isOpen={showReminderPopup}
            onClose={() => {
              setShowReminderPopup(false);
            }}
            onCheckout={() => {
              Backend.userSwipeIn({
                userUID: UID,
                userSwipeNum: input,
                lab: currLab.id,
              });
            }}
          ></SwipeOutReminderPopup>
        )}
        {showChoicePopup && (
          <SwipeOrReservePopup
            isOpen={showChoicePopup}
            onClose={() => {
              setShowChoicePopup(false);
            }}
            onCheckOut={() => {
              Backend.userSwipeOut(UID, currLab.id);
            }}
          ></SwipeOrReservePopup>
        )}
        {showAdminPopup && (
          <AdminPopup
            isOpen={showAdminPopup}
            onClose={() => {
              setShowAdminPopup(false);
            }}
            onCheckout={() => {
              popupIfUserAutoSwipedOut(UID, currLab.id);
            }}
          ></AdminPopup>
        )}
      </Stack>
    </div>
  );
}

KioskLogin.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRouter(KioskLogin);
